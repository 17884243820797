import Bugsnag from "@bugsnag/js";
import axios from "axios";
import React, { Component } from "react";
import cookie from "react-cookies";
import CurrencyFormat from "react-currency-format";
import Marquee from "react-fast-marquee";
import Stories from "react-insta-stories";

import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import PlacesAutocomplete from "../../components/common/PlacesAutocomplete";
import PriceView from "../../components/common/PriceView";
import LetterByLetter from "../../components/common/LetterByLetter";
import ServicesList from "../../components/common/ServicesList";
import VehiclesTypes from "../../components/Vehicles/Vehicles";
import constants from "../../Constants";
import util from "../../util";
import news from "../articles/news";
import reviews from "../articles/reviews";

const baseURL = constants.baseURL;
class Section extends Component {
  constructor() {
    super();

    let promoCode = "";
    let vehicleId = "";
    let truckType = "";
    let justbook = "";
    let deliverylat = "";
    let collectiondate = "";
    let collectiontime = "";
    let description = "";
    let deliverylng = "";
    let collectionlat = "";
    let collectionlng = "";
    let category = "handheld-items";
    let runners = 1;
    let floors = 0;
    let prospectemail = "";
    let prospectmobilenumber = "";
    try {
      const queryParams = new URLSearchParams(window.location.search);
      promoCode = queryParams.get("promocode") || "";
      truckType = queryParams.get("trucktype") || "1.5tonopen";
      justbook = queryParams.get("justbook") || "";
      description = queryParams.get("description") || "";
      deliverylat = queryParams.get("deliverylat") || "";
      deliverylng = queryParams.get("deliverylng") || "";
      collectiondate = queryParams.get("collectiondate") || "";
      collectiontime = queryParams.get("collectiontime") || "";
      collectionlat = queryParams.get("collectionlat") || "";
      collectionlng = queryParams.get("collectionlng") || "";
      category = queryParams.get("category") || "handheld-items";
      runners = queryParams.get("runners") || 1;
      floors = queryParams.get("floors") || 0;
      prospectemail = queryParams.get("prospectemail") || "";
      prospectmobilenumber = queryParams.get("prospectmobilenumber") || "";
      vehicleId = queryParams.get("v") || "";
    } catch (error) {
      Bugsnag.notify(error);
    }

    this.state = {
      justbook,
      loading: false,
      success: false,
      trackYourOrder: false,
      openSuccessModal: false,
      truckType,
      acceptedOffer: "",
      apierror: "",
      orderid: "",
      collection: {
        lat: collectionlat,
        lng: collectionlng,
      },
      delivery: {
        lat: deliverylat,
        lng: deliverylng,
      },
      fullQuote: {
        tripDistance: 0,
        tripDuration: 0,
        sellingPrice: 0,
        storedQuoteId: "",
      },
      firstname: "",
      lastname: "",
      email: prospectemail,
      mobilenumber: prospectmobilenumber,
      collectiondate,
      collectiontime,
      description,
      duration: 0,
      trips: 0,
      prospectemail,
      prospectmobilenumber,
      vehicletypes: [],
      vehiclecategories: [],
      showOtherCategories: false,
      usercategory: category,
      vehicleconfigurations: {},
      openDiscountCode: false,
      promoCode: promoCode || "",
      vehicleId: vehicleId || "",
      runners,
      floors,
      vehicleProfile: {},
      stories: [],
      expanded: [],
    };
  }

  componentDidMount() {
    this.getVehicleProfile();

    if (this.validStepOne()) this.getQuote();
  }

  getVehicleProfile() {
    const { vehicleId, truckType } = this.state;
    if (vehicleId?.length > 3)
      axios
        .post(baseURL + "/vehicle-profile", {
          vehicleid: vehicleId,
          source_:
            util.navigator_() + util.source_() + " qtref:" + document?.referrer,
          quotevehicleid: this.state.vehicleProfile.vehicleid,
        })
        .then((repos) => {
          const vehicleProfile = repos.data;
          this.setState({
            vehicleProfile,
            truckType: vehicleProfile.trucktype || truckType,
          });
          if (vehicleProfile?.idnumber?.length > 0)
            window.document.getElementById("pagefragmenttop").scrollIntoView();
        });
  }
  getVehicleTypes() {
    const { vehicletypes } = this.state;
    if (vehicletypes?.length === 0)
      axios.get(baseURL + "/vehicle-types").then((repos) => {
        const vehicletypesdata = repos.data;
        this.setState({
          vehicletypes: vehicletypesdata?.vehicles,
          vehiclecategories: vehicletypesdata?.categories,
          vehicleconfigurations: vehicletypesdata?.configurations,
        });
      });
  }

  changeTruck() {
    this.setState(
      {
        truckType: "",
        truckTonnes: undefined,
        fullQuote: {
          tripDistance: 0,
          tripDuration: 0,
          sellingPrice: 0,
          storedQuoteId: "",
          discount: "",
        },
      },
      () => {
        if (window.document.getElementById("vehiclelist"))
          window.document.getElementById("vehiclelist").scrollIntoView();
      }
    );
  }
  validStepOne() {
    const {
      truckType,
      delivery,
      collection,
      prospectemail,
      prospectmobilenumber,
    } = this.state;
    if (prospectemail.length === 0) return false;
    if (prospectmobilenumber.length === 0) return false;
    if (truckType === "") return false;
    if (delivery.lat === 0) return false;
    if (collection.lat === 0) return false;
    return !(
      collection.lat === delivery.lat && collection.lng === delivery.lng
    );
  }
  validStepTwo() {
    const {
      firstname,
      lastname,
      email,
      mobilenumber,
      collectiondate,
      collectiontime,
      description,
    } = this.state;

    return !(
      firstname.length === 0 ||
      lastname.length === 0 ||
      email.length === 0 ||
      mobilenumber.length === 0 ||
      collectiondate.length === 0 ||
      collectiontime.length === 0 ||
      description.length === 0
    );
  }
  getQuote() {
    const {
      truckType,
      delivery,
      collection,
      usercategory,
      promoCode,
      runners,
      collectiondate, //required
      collectiontime, //required
      floors,
      description,
      duration,
      trips,
      prospectemail,
      prospectmobilenumber,
    } = this.state;
    if (!this.validStepOne()) return;

    this.setState({
      loading: true,
      apierror: "",
      acceptedOffer: "",
      fullQuote: {
        tripDistance: 0,
        tripDuration: 0,
        sellingPrice: 0,
        storedQuoteId: "",
        discount: "",
      },
    });
    axios
      .post(baseURL + "/quote", {
        description,
        duration,
        trips,
        truckType: this.state.vehicleProfile.trucktype || truckType,
        delivery,
        collection,
        category: usercategory,
        promocode: promoCode,
        runners,
        floors,
        prospectemail,
        collectiondate, //required?
        collectiontime, //required?
        prospectmobilenumber,
        source_:
          util.navigator_() + util.source_() + " qtref:" + document?.referrer,
        quotevehicleid: this.state.vehicleProfile.vehicleid,
      })
      .then((response) => {
        if (response.data.sellingPrice > 0) {
          this.setState(
            {
              loading: false,
              fullQuote: {
                tripDistance: response.data.tripDistance,
                tripDuration: response.data.tripDuration,
                sellingPrice: response.data.sellingPrice,
                storedQuoteId: response.data.quoteId,
                discount: response.data.discount,
                otherOptions: response.data.otherOptions,
              },
            },
            () => {
              if (window.document.getElementById("mainquote"))
                window.document.getElementById("mainquote").scrollIntoView();
            }
          );
        }
      })
      .catch((e) => {
        this.setState({
          loading: false,
          apierror: "Check your addresses and try again.",
        });
        Bugsnag.notify(e);
      });
  }
  acceptQuote() {
    this.setState({
      loading: true,
      apierror: "",
    });
    const {
      fullQuote,
      firstname,
      lastname,
      email,
      mobilenumber,
      collectiondate,
      collectiontime,
      description,
      acceptedOffer,
    } = this.state;

    axios
      .post(baseURL + "/accept-quote", {
        fullQuote,
        firstname,
        lastname,
        email,
        mobilenumber,
        collectiondate,
        collectiontime,
        description,
        acceptedOffer: acceptedOffer?.toLowerCase(),
      })
      .then((response) => {
        this.setState(
          {
            loading: false,
            success: response.status === 200,
            openSuccessModal: true,
            orderid: response?.data?.orderid,
          },
          () => {
            if (response.status === 200 && response?.data?.orderid?.length > 0)
              cookie.save(
                "currentOrder",
                `email=${this.state.email}&orderid=${response.data.orderid}`,
                {
                  path: "/",
                  maxAge: 86400,
                  secure: true,
                }
              );
          }
        );
      })
      .catch((e) => {
        this.setState({
          loading: false,
          apierror: "Request failed. Please try again.",
        });
      });
  }
  vehiclePriceTip(vehicle, change) {
    function roundToNearestFifty(num) {
      return Math.round(num / 50) * 50;
    }
    let costOfDelivery =
      2000 * vehicle?.pricePerMeterCents + //cents
      1200 * vehicle?.pricePerSecondCents + //cents for 10 minutes
      vehicle?.handlingFeeCents; //cents
    let serviceFeePercentage = vehicle?.serviceFeePercentage * costOfDelivery;
    return roundToNearestFifty(
      ///hack for days /////!!!!!! needs review
      Math.round(
        ((costOfDelivery + serviceFeePercentage) * 1.15 * change || 1) / 100
      )
    );
  }
  getImage(trucktype) {
    try {
      return `https://media.qiktruck.co.za/${trucktype}.png`;
    } catch (error) {
      return "";
    }
  }
  getProfileImage(photo) {
    try {
      return `https://advertsmedia.qiktruck.co.za/${photo}`;
    } catch (error) {
      return "";
    }
  }
  expandVehicleView(str) {
    let arr = [...this.state.expanded];
    let index = arr.indexOf(str);
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(str);
    }
    this.setState({ expanded: arr });
  }
  getHappyDriver(num) {
    return (
      <img
        src={"https://media.qiktruck.co.za/happydriver" + (num + 1) + ".png"}
        alt=""
        className={"happydriver"}
      />
    );
  }
  render() {
    this.getVehicleTypes();
    const {
      vehicletypes,
      promoCode,
      runners,
      floors,
      fullQuote: { discount, sellingPrice },
      loading,
      truckType,
    } = this.state;
    return (
      <React.Fragment>
        <div id={"pagefragmenttop"}></div>
        {this.state?.stories?.length > 0 && (
          <Stories
            stories={this.state?.stories}
            defaultInterval={1500}
            width={"100vw"}
            height={"100vh"}
            onAllStoriesEnd={() => this.setState({ stories: [] })}
          />
        )}
        {this.props.seo}
        <section className="hero-section" id={"pagetop"}>
          <Modal isOpen={this.state.success && this.state.openSuccessModal}>
            <ModalHeader>
              <i className={"mdi mdi-check orange-circle  order-received"}></i>
              Order Received
            </ModalHeader>
            <ModalBody>
              <ListGroup className="black-text">
                <ListGroupItem>
                  <i
                    className={
                      "mdi mdi-check orange-circle order-received-list"
                    }
                  ></i>
                  A driver will be in touch shortly to confirm your order.
                </ListGroupItem>
                <ListGroupItem>
                  <i className={"mdi mdi-email order-received-list"}></i>
                  You will receive an email with your invoice shortly.
                </ListGroupItem>
                <ListGroupItem>
                  <i
                    className={"mdi mdi-cash-multiple order-received-list"}
                  ></i>
                  You can pay now or after the delivery.
                </ListGroupItem>
                <ListGroupItem>
                  <i
                    className={"mdi mdi-cash-multiple order-received-list"}
                  ></i>
                  Follow the link in your email to make a payment.
                </ListGroupItem>
                <ListGroupItem>
                  <i
                    className={"mdi mdi-account-alert order-received-list"}
                  ></i>
                  Produce your ID when the driver arrives.
                </ListGroupItem>
                <ListGroupItem>
                  <i
                    className={
                      "mdi mdi-check orange-circle order-received-list"
                    }
                  ></i>
                  Happy moving!
                </ListGroupItem>
              </ListGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() =>
                  this.setState(
                    {
                      openSuccessModal: false,
                      trackYourOrder: true,
                    },
                    () => {
                      window.location.href =
                        "/track-order?paynow=yes&orderid=" +
                        this.state.orderid +
                        "&email=" +
                        this.state.email;
                    }
                  )
                }
              >
                Okay
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={this.state.openDiscountCode}>
            <ModalHeader>
              <i className={"mdi mdi-clipboard-text order-received"}></i>
              More options
            </ModalHeader>
            <ModalBody>
              <Label> Add Helpers </Label>
              <br />
              <span>
                How many people do you need to carry and load the goods? The
                driver may not be able to help.
              </span>
              <Input
                id="runners"
                name="runners"
                type="number"
                defaultValue={runners}
                min={0}
                onChange={(e) => {
                  this.setState({ runners: parseInt(e.target.value, 10) });
                }}
              />
              <br />
              <br />
              <Label>Add Floors </Label>
              <br />
              <span>
                How many floors are we required to carry the goods? Add more
                information in your description later.
              </span>
              <Input
                id="floors"
                name="floors"
                type="number"
                min={0}
                defaultValue={floors}
                onChange={(e) => {
                  this.setState({ floors: parseInt(e.target.value, 10) });
                }}
              />
              <br />
              <br />
              <Label>Discount code</Label>
              <br />
              <span>Lucky one! Our Discount codes are up to 30% off!</span>
              <Input
                id="promocode"
                name="promocode"
                type="promocode"
                defaultValue={promoCode}
                placeholder="Type your discount code here |"
                onChange={(e) => {
                  this.setState({ promoCode: e.target.value.toUpperCase() });
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => {
                  this.setState({
                    openDiscountCode: false,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  this.getQuote();
                  this.setState({
                    openDiscountCode: false,
                  });
                }}
              >
                Apply
              </Button>
            </ModalFooter>
          </Modal>
          <Container>
            {!this.state.vehicleProfile?.firstname && !this.state.justbook && (
              <Row>
                <Col lg={12}>
                  <div className="hero-wrapper mb-4">
                    {this.props.heading || (
                      <h2 className="main-headline">
                        <LetterByLetter />
                        <br />
                        <VehiclesTypes />
                      </h2>
                    )}
                    {this.props.description && <p>{this.props.description}</p>}
                  </div>
                </Col>
              </Row>
            )}
            {!this.state.vehicleProfile?.firstname &&
              (this.props.services || <ServicesList />)}
            <div className="store-front">
              <Row>
                <Col lg={12}>
                  <div className="divvyup b-center"></div>
                </Col>
                <Col lg={12}>
                  <div className="hero-wrapper mb-4" id={"quotestart"}>
                    {!this.state.vehicleProfile?.firstname ? (
                      <>
                        {/* <h3
                          className="font-18"
                          onClick={() => this.changeTruck()}
                        >
                          Option 1:
                        </h3>
                        <h6>Submit goods list</h6>
                        <p>
                          Do you have the Length, Width, Height and Weight of
                          your goods? Submit a list here.{" "}
                          <a href="/goods-list?nointro=true" className="orange">
                            Goods list calculator.
                          </a>
                        </p>
                        <div className="divvy b-center"></div>
                        <h3
                          className="font-18"
                          onClick={() => this.changeTruck()}
                        >
                          Option 2:
                        </h3> */}
                        <h6>Choose a vehicle size and driver below</h6>
                        <br />
                        <div id={"vehiclelist"}></div>
                        {!this.state.vehicleProfile?.firstname && (
                          <Row>
                            {vehicletypes
                              ?.filter((v) => v.sell === true)
                              ?.map((vehicle, index) => {
                                const image = this.getImage(vehicle.type);
                                return (
                                  <Col
                                    key={vehicle.type}
                                    lg={4}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                    xl={4}
                                    className={
                                      "grey-border-bottom vehicle-type"
                                    }
                                  >
                                    {this.state.expanded.indexOf(
                                      vehicle.type
                                    ) === -1 ? (
                                      <div
                                        onClick={() =>
                                          this.expandVehicleView(vehicle.type)
                                        }
                                      >
                                        <span className="loadgroup">
                                          {vehicle.loadgroup}
                                        </span>
                                        <img
                                          src={image}
                                          alt=""
                                          className={
                                            "menu-truck " +
                                            (this.state.truckType ===
                                            vehicle.type
                                              ? "menu-truck-selected"
                                              : "")
                                          }
                                          height="20"
                                        />
                                        <b className="add-on">+</b>
                                        <img
                                          src={
                                            "https://media.qiktruck.co.za/happydriver0.png"
                                          }
                                          alt=""
                                          className={"happydriver"}
                                        />
                                        <span className="price-tag-from ">
                                          From
                                        </span>
                                        <b className="price-tag">
                                          {" "}
                                          R{this.vehiclePriceTip(vehicle, 1)}
                                        </b>
                                        <b className="price-tag-promo">
                                          {this.vehiclePriceTip(
                                            vehicle,
                                            1.13
                                          ) !== this.vehiclePriceTip(vehicle, 1)
                                            ? " R" +
                                              this.vehiclePriceTip(
                                                vehicle,
                                                1.13
                                              )
                                            : undefined}
                                        </b>
                                        <span
                                          className="view-more-menu"
                                          onClick={() =>
                                            this.setState({
                                              expanded: [
                                                vehicle.type,
                                                ...this.state.expanded,
                                              ],
                                            })
                                          }
                                        >
                                          view more.
                                        </span>
                                      </div>
                                    ) : (
                                      <div>
                                        <span className="loadgroup">
                                          {vehicle.loadgroup}
                                        </span>
                                        <div
                                          className={
                                            this.state.truckType ===
                                            vehicle.type
                                              ? "selected-truck"
                                              : ""
                                          }
                                        ></div>
                                        <img
                                          src={image}
                                          alt=""
                                          className={
                                            "menu-truck " +
                                            (this.state.truckType ===
                                            vehicle.type
                                              ? "menu-truck-selected"
                                              : "")
                                          }
                                          height="20"
                                        />
                                        <img
                                          src={
                                            "https://media.qiktruck.co.za/happydriver.gif"
                                          }
                                          alt=""
                                          className={"happydriver"}
                                        />
                                        <i
                                          className={
                                            "mdi mdi-close-circle-outline ml-2 close price-tag"
                                          }
                                          onClick={() =>
                                            this.expandVehicleView(vehicle.type)
                                          }
                                        ></i>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              "<p class='menu-truck-tip'>" +
                                              vehicle.tip.longform +
                                              "</p>",
                                          }}
                                        ></div>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              "<p class='menu-truck-tip examples'> <i class='mdi mdi-lightbulb ml-2 orange'></i>" +
                                              vehicle.tip.examples +
                                              "</p>",
                                          }}
                                        ></div>
                                        <p className="menu-truck-tip ">
                                          <span className="driver-included">
                                            <i className="mdi mdi-star ml-2 orange">
                                              {" "}
                                            </i>
                                            {vehicle.tip.benefits}
                                          </span>
                                          <br />
                                          <br />
                                          <span>
                                            Now only:{" "}
                                            <b>
                                              {" "}
                                              R
                                              {this.vehiclePriceTip(vehicle, 1)}
                                            </b>
                                          </span>{" "}
                                          for short distance trips.
                                        </p>
                                        <div className="body-options">
                                          <p className="select-body-prompt">
                                            Select a body type
                                          </p>
                                          {vehicletypes
                                            ?.filter(
                                              (v) =>
                                                v.loadgroup ===
                                                vehicle.loadgroup
                                            )
                                            .map((m) => {
                                              const imge = this.getImage(
                                                m.type
                                              );
                                              return (
                                                <button
                                                  key={m.type}
                                                  className="option-button"
                                                  onClick={() =>
                                                    this.setState(
                                                      {
                                                        truckType: m.type,
                                                        truckTonnes: m.tonnes,
                                                      },
                                                      () => {
                                                        this.getQuote();
                                                        if (
                                                          window.document.getElementById(
                                                            "addresses"
                                                          )
                                                        )
                                                          window.document
                                                            .getElementById(
                                                              "addresses"
                                                            )
                                                            .scrollIntoView();
                                                        setTimeout(
                                                          () =>
                                                            window.document
                                                              .getElementById(
                                                                "addresses"
                                                              )
                                                              .scrollIntoView(),
                                                          1000
                                                        );
                                                      }
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={imge}
                                                    alt=""
                                                    className={
                                                      "menu-mini-truck "
                                                    }
                                                    height="20"
                                                  />
                                                  <span>{m.bodylabel}</span>
                                                </button>
                                              );
                                            })}
                                          <span className="category-hastags">
                                            {vehicle.categories.map(
                                              (v) => "    #" + v
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </Col>
                                );
                              })}
                            <Col lg={12}>
                              <div className="divvyup b-center"></div>
                            </Col>
                          </Row>
                        )}
                      </>
                    ) : (
                      <Row>
                        <h3 className="font-18">Your driver for this trip.</h3>
                        {/* 
                          dateadded: "2022-03-08T16:52:11.825Z"
                          driverid: "9a1b41bd-405c-4969-a9f8-aa8ed74d9fdb"
                          driverlicense: "0MMMMMMMM"
                          driverstatus: "available"
                          email: "matsiyana.e+d1@gmail.com"
                          enginenumber: "noenginenumber-t4m63rjttx"
                          firstname: "0MMMMMMMM"
                          homelat: -26.0915105
                          homelng: 28.0030464
                          idnumber: "0MMMMMMMM"
                          lastname: "0MMMMMMMM"
                          licensenumber: "08mar08mar08mar"
                          loadcovertype: "noloadcovertype-h6e0emyshs"
                          make: "nomake-ilx0erobry"
                          mobilenumber: "0824277002"
                          model: "nomodel-o5y40z7nlp"
                          ownerdriverid: "6e2b0b11-145b-479d-9dbd-9f6c65d17c91"
                          source_: null
                          trucktype: "1.5tonopen"
                          vehicleid: "b59d4b62-2dff-4302-9c70-202c874c70f3"
                          vehicleidx: null
                          vehiclestatus: "in-review"
                          vin: "08mar08mar08mar"
                          yearcreated: "noyearcreated-qj7vs8w53n" 
                        */}
                        <div className="vehicle-profile">
                          <div className="vehicle-profile-avatar">
                            <div className="vehicle-profile-avatar"></div>
                            <div className="vehicle-profile-vehicletype">
                              <img
                                src={this.getImage(
                                  this.state.vehicleProfile.trucktype
                                )}
                                alt=""
                                height={60}
                              />
                            </div>
                          </div>
                          <div className="vehicle-profile-data">
                            <div className="vehicle-profile-names driver-below ">
                              <i className="mdi mdi-face ml-2"></i>{" "}
                              {this.state.vehicleProfile.firstname}{" "}
                              {this.state.vehicleProfile.lastname}
                            </div>
                            <div className="vehicle-profile-number">
                              <div>
                                <i className="mdi mdi-phone ml-2"></i>{" "}
                                07xxxxxxxxx
                              </div>
                            </div>
                          </div>
                          <div className="vehicle-profile-images">
                            {this.state.vehicleProfile?.images?.map(
                              (v, index) => (
                                <img
                                  key={index}
                                  src={this.getProfileImage(v)}
                                  onClick={() => {
                                    if (
                                      window.document.getElementById(
                                        "pagefragmenttop"
                                      )
                                    )
                                      window.document
                                        .getElementById("pagefragmenttop")
                                        .scrollIntoView();

                                    this.setState({
                                      stories:
                                        this.state.vehicleProfile?.images?.map(
                                          (m) => this.getProfileImage(m)
                                        ),
                                    });
                                  }}
                                  alt=""
                                  height={60}
                                />
                              )
                            )}
                          </div>
                          <p>
                            Your trip will go straight to this driver for
                            review.
                          </p>
                        </div>
                      </Row>
                    )}
                    <p></p>
                    <p></p>
                    <Row>
                      <Col lg={6} sm={6}>
                        <FormGroup autoComplete="new-password">
                          <h3
                            className="font-18"
                            id={"addresses"}
                            onClick={() => this.changeTruck()}
                          >
                            Pick up address{" "}
                          </h3>
                          <PlacesAutocomplete
                            type="text"
                            className="form-control"
                            id="collection"
                            placeholder=""
                            required
                            autoComplete="new-password"
                            onPlaceSelected={(place) => {
                              if (!place?.geometry?.location?.lat()) {
                                Bugsnag.notify(
                                  new Error(
                                    "COLLECTION place not found: " +
                                      this.state.truckType
                                  )
                                );
                              }
                              this.setState({
                                collection: {
                                  lat: place?.geometry?.location?.lat(),
                                  lng: place?.geometry?.location?.lng(),
                                },
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup className="mb-4" autoComplete="new-password">
                          <h3 className="font-18">Drop off address </h3>
                          <PlacesAutocomplete
                            type="text"
                            className="form-control"
                            id="delivery"
                            placeholder=""
                            required
                            autoComplete="new-password"
                            onPlaceSelected={(place) => {
                              if (!place?.geometry?.location?.lat()) {
                                Bugsnag.notify(
                                  new Error(
                                    "DELIVERY place not found: " +
                                      this.state.truckType
                                  )
                                );
                              }
                              this.setState({
                                delivery: {
                                  lat: place?.geometry?.location?.lat(),
                                  lng: place?.geometry?.location?.lng(),
                                },
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <span>
                            {this.state.truckType
                              ?.replace("open", "")
                              ?.replace("closed", "")}
                          </span>
                          <br />
                          {this.state.truckTonnes
                            ? "Max Tonnes: " + this.state.truckTonnes
                            : ""}
                          {this.state.vehicleProfile?.firstname && (
                            <div className="selected-button-truck">
                              <img
                                src={this.getImage(this.state.truckType)}
                                alt=""
                              />
                            </div>
                          )}
                          {!this.state.vehicleProfile?.firstname && (
                            <div
                              className="selected-button-truck"
                              onClick={() => {
                                this.changeTruck();
                              }}
                            >
                              <img
                                src={this.getImage(this.state.truckType)}
                                alt=""
                              />
                              <br />

                              {this.state.truckType
                                ? "Change vehicle >"
                                : "No vehicle selected >"}
                              <br />
                              <br />
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Label for="description">Options</Label>
                          <div
                            onClick={() =>
                              this.setState({ openDiscountCode: true })
                            }
                            className="moreoptions"
                          >
                            {floors} Floor{floors === 1 ? "" : "s"}. {runners}{" "}
                            Helper
                            {runners === 1 ? "" : "s"}.
                            {promoCode?.length > 0
                              ? "- Discount code: " + promoCode
                              : " Discounts"}
                            {" >"}
                            <span></span>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Input
                            id="prospectemail"
                            name="prospectemail"
                            placeholder="Email *"
                            defaultValue={this.state.prospectemail}
                            onChange={(e) => {
                              this.setState({
                                prospectemail: e.target.value,
                                email: e.target.value,
                              });
                            }}
                          />
                        </FormGroup>{" "}
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Input
                            id="prospectmobilenumber"
                            name="prospectmobilenumber"
                            placeholder="Mobile number *"
                            defaultValue={this.state.prospectmobilenumber}
                            onChange={(e) => {
                              this.setState({
                                prospectmobilenumber: e.target.value,
                                mobilenumber: e.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={12} sm={6}>
                        <FormGroup>
                          <Input
                            id="description"
                            name="text"
                            type="textarea"
                            defaultValue={this.state.description}
                            style={{ minHeight: 120 }}
                            placeholder="Details about what we are moving... *"
                            onChange={(e) => {
                              this.setState({ description: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Label for="collectiondate1">Collection date</Label>
                          <Input
                            id="collectiondate1"
                            name="collectiondate1"
                            placeholder="Collection date"
                            defaultValue={this.state.collectiondate}
                            type="date"
                            onChange={(e) => {
                              this.setState({ collectiondate: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Label for="collectiontime1">Time</Label>
                          <Input
                            id="collectiontime1"
                            name="collectiontime1"
                            defaultValue={this.state.collectiontime}
                            placeholder="Time"
                            type="time"
                            onChange={(e) => {
                              this.setState({ collectiontime: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="instant-quotations-parent">
                      <Button
                        className="instant-quotations"
                        color="primary"
                        onClick={() => {
                          this.getQuote();
                        }}
                        disabled={!this.validStepOne() || loading}
                      >
                        GET QUOTATIONS
                        <i className="mdi mdi-arrow-down-bold ml-2"></i>
                      </Button>
                    </div>

                    {this.state.loading === false &&
                      this.state.apierror.length > 0 && (
                        <p className="error-message">{this.state.apierror}</p>
                      )}
                  </div>
                </Col>
              </Row>
              <Row id={"mainquote"}>
                <Col lg={4} sm={12} className={"sellingPrice "}></Col>
                <PriceView
                  sellingPrice={sellingPrice || 0}
                  discount={discount}
                  loading={loading}
                  disabled={
                    typeof sellingPrice === "undefined" ||
                    sellingPrice === null ||
                    sellingPrice === 0 ||
                    loading
                  }
                  setPromoCode={(promoCode) => {
                    this.setState({ promoCode }, () => {
                      this.getQuote();
                    });
                  }}
                  trucktype={truckType}
                  acceptOffer={() => {
                    console.log("acceptOffer");
                    this.setState({ acceptedOffer: "sellingPrice" }, () => {
                      if (window.document.getElementById("makebooking"))
                        window.document
                          .getElementById("makebooking")
                          .scrollIntoView();
                    });
                  }}
                />

                <Col lg={4} sm={12} className={"sellingPrice "}></Col>
              </Row>
              {this.state.fullQuote.tripDistance > 0 && (
                <p className="trip-info">
                  <span>
                    {Math.round(this.state.fullQuote.tripDistance / 1000)} km
                    for{" "}
                  </span>
                  <span>
                    {Math.round(this.state.fullQuote.tripDuration / 60)} minutes
                    <i className="mdi mdi-star ml-2"></i>
                  </span>{" "}
                  once off trip.
                </p>
              )}
            
              {this.state.fullQuote.sellingPrice > 0 &&
                this.state.acceptedOffer && (
                  <div>
                    <br />
                    <h3
                      id={"makebooking"}
                      style={{
                        borderTop: " 1px solid #ccc",
                        paddingTop: 15,
                      }}
                    >
                      Schedule your trip
                      <span className="price-heading">
                        {" "}
                        at{" "}
                        <CurrencyFormat
                          value={
                            this.state.fullQuote[this.state.acceptedOffer] / 100
                          } //cents to rands
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"R"}
                          decimalScale={2}
                        />
                      </span>
                    </h3>

                    <Row>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Label for="firstname">Your Firstname</Label>
                          <Input
                            id="firstname"
                            name="firstname"
                            type="text"
                            onChange={(e) => {
                              this.setState({ firstname: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Label for="lastname">Surname</Label>
                          <Input
                            id="lastname"
                            name="lastname"
                            type="text"
                            onChange={(e) => {
                              this.setState({ lastname: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Label for="email">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            defaultValue={this.state.email}
                            onChange={(e) => {
                              this.setState({ email: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Label for="mobilenumber">Mobile number</Label>
                          <Input
                            id="mobilenumber"
                            name="mobilenumber"
                            type="tel"
                            defaultValue={this.state.mobilenumber}
                            onChange={(e) => {
                              this.setState({ mobilenumber: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Label for="collectiondate">Collection date</Label>
                          <Input
                            id="collectiondate"
                            name="collectiondate"
                            placeholder="Collection date"
                            defaultValue={this.state.collectiondate}
                            type="date"
                            onChange={(e) => {
                              this.setState({ collectiondate: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6} sm={6}>
                        <FormGroup>
                          <Label for="collectiontime">Time</Label>
                          <Input
                            id="collectiontime"
                            defaultValue={this.state.collectiontime}
                            name="collectiontime"
                            placeholder="Time"
                            type="time"
                            onChange={(e) => {
                              this.setState({ collectiontime: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} sm={12}>
                        <FormGroup>
                          <Label for="description">Instructions</Label>
                          <Input
                            id="description"
                            name="text"
                            type="textarea"
                            defaultValue={this.state.description}
                            style={{ minHeight: 120 }}
                            placeholder="Describe what you want us to move... Suggest a price to the driver.."
                            onChange={(e) => {
                              this.setState({ description: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {this.state.loading === true && "searching for a driver..."}
                    {this.state.loading === false &&
                      this.state.apierror.length > 0 && (
                        <p className="error-message">{this.state.apierror}</p>
                      )}
                    <Button
                      className="float-right mb-4"
                      color="primary"
                      onClick={() => {
                        this.acceptQuote();
                      }}
                      disabled={!this.validStepTwo() || this.state.loading}
                    >
                      Next
                      <i className="mdi mdi-arrow-right ml-2"></i>
                    </Button>
                    <div
                      className="selected-button-truck"
                      onClick={() => {
                        this.setState(
                          {
                            truckType:
                              this.state.vehicleProfile.trucktype || "",
                          },
                          () => {
                            if (window.document.getElementById("vehiclelist"))
                              window.document
                                .getElementById("vehiclelist")
                                .scrollIntoView();
                          }
                        );
                      }}
                    >
                      <div className="highlight"></div>
                      <img src={this.getImage(this.state.truckType)} alt="" />
                      <br />
                      {this.state.vehicleProfile.trucktype ? (
                        <div />
                      ) : (
                        <>
                          {this.state.truckType
                            ? "Change vehicle?"
                            : "No vehicle selected"}
                        </>
                      )}
                    </div>
                  </div>
                )}
            </div>

            <h3 className="font-18">Customer reviews</h3>
            <Marquee
              gradient={true}
              gradientWidth={0}
              speed={6}
              direction="right"
            >
              {reviews.map((a, index) => (
                <div key={index}>
                  <div className="home-reviews">
                    <h1>{a.heading}</h1>
                    <div className="date-article-added">
                      <i className="mdi mdi-clock-outline ml-2"></i>
                      {a.date}
                      <i className="mdi mdi-pin ml-2"></i>
                      {a.location}
                      {Array.from({ length: a.category }).map((x, index) => (
                        <i className="mdi mdi-star" key={index}></i>
                      ))}
                    </div>
                    <p>{a.copy}</p>
                  </div>
                </div>
              ))}
            </Marquee>
            <br />
            <h3>Where are we located?</h3>
            <div className="activity-map">
              <br />
              <img
                alt="qiktruck-activity-map"
                src="https://media.qiktruck.co.za/qiktruck-activity-map.png"
              />{" "}
              <ul className="map-benefits">
                <li>
                  We offer transportation services to all areas in South Africa.
                </li>
                <li>
                  <b>
                    We offer you lower prices because our vehicles already
                    operate in your pickup area.
                  </b>
                </li>
                <li>Each order is tailored to the needs of your load.</li>
                <li>
                  <b>Drivers, Vehicles and Bookings are managed by QikTruck.</b>
                </li>
                <li>
                  Drivers approve quotations and orders on the driver app.
                </li>
                <li>
                  <b>Trucks. Anytime. Quick.</b>
                </li>
              </ul>
            </div>

            <br />
            {this.props.rows}
            <br />
            <h3 className="font-18">In the News</h3>
            <Marquee
              gradient={true}
              gradientWidth={0}
              speed={6}
              direction="right"
            >
              {news.map((a, index) => (
                <div key={index}>
                  <a
                    className="home-news"
                    href={"/articles/" + a?.pathname?.toLocaleLowerCase()}
                  >
                    <div
                      className="article-list-image"
                      style={{
                        backgroundImage: `url(https://media.qiktruck.co.za/${a.image})`,
                      }}
                      alt={a.image}
                    />
                    <h1>{a.heading}</h1>
                    <div className="date-article-added">
                      <i className="mdi mdi-clock-outline ml-2"></i>
                      {a.date}
                      <i className="mdi mdi-pin ml-2"></i>
                      {a.category}
                    </div>
                    <p>{a.copy}</p>
                  </a>
                </div>
              ))}
            </Marquee>
            <br />
            <br />
          </Container>
          <div style={{ display: "none", color: "white", height: 0, width: 0 }}>
            {vehicletypes?.map((v, index) => {
              const image = this.getImage(v.type);
              return (
                <div key={index}>
                  <h2>{v.type}</h2>
                  <img src={image} alt={v.tip.shortform} />
                  <h2>{v.type}</h2>
                  <h2>{v.displaytype}</h2>
                  <h2>{v.longform}</h2>
                </div>
              );
            })}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;